<template>
  <validation-observer
    ref="diplomaSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="diplomaSaveModal"
      body-class="position-static"
      size="lg"
      centered
      no-close-on-backdrop
      :title="isCreated ? `Thêm ${resourceName.fullName}` : `Cập nhật ${resourceName.fullName}`"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-form-group label-for="name">
          <template v-slot:label>
            Tên {{ resourceName.fullName }} <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            :name="`Tên ${resourceName.fullName}`"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="targetData.name"
              name="name"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="courseId">
          <template v-slot:label>
            Chương trình đào tạo/Bồi dưỡng <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Chương trình đào tạo/Bồi dưỡng"
            rules="required"
          >
            <v-select
              v-model="targetData.trainingSystemId"
              :options="trainingSystems"
              :reduce="option => option.value"
              @input="onTrainingSystemsChange"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group label-for="courseId">
              <template v-slot:label>
                Khóa học <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Khóa học"
                rules="required"
              >
                <v-select
                  id="selectCourses"
                  v-model="targetData.courseId"
                  :options="courses"
                  :reduce="option => option.value"
                  @input="onCoursesChange"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group label-for="courseSemesterId">
              <template v-slot:label>
                Học kỳ <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Học kỳ"
                rules="required"
              >
                <v-select
                  v-model="targetData.courseSemesterId"
                  :options="courseSemesters"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="8"
          >
            <b-form-group label-for="programmeId">
              <template v-slot:label>
                Chương trình đào tạo <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Chương trình đào tạo"
                rules="required"
              >
                <v-select
                  id="programmeId"
                  v-model="targetData.programmeId"
                  :options="programmes"
                  label="name"
                  :reduce="option => option.id"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetData.status"
                  :disabled="targetData.status === 2"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('diplomaSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BModal, BOverlay, BFormInvalidFeedback, BButton, BRow, BCol, BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { getUser } from '@/auth/utils'

export const Attr = Object.freeze({
  store: 'diploma',
})
export default {
  name: 'DiplomaSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BCol,
    BRow,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetData: {
        id: null,
        code: '',
        trainingSystemId: null,
        courseId: null,
        courseSemesterId: null,
        classId: null,
        status: 1,
        orderNo: '',
      },
      required,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      statuses: `${Attr.store}/statuses`,
      resourceName: `${Attr.store}/resourceName`,
      courses: 'dropdown/courses',
      trainingSystems: 'dropdown/trainingSystems',
      courseSemesters: 'dropdown/courseSemesters',
      programmes: 'programme/programmes',
    }),
    statusOptions() {
      return this.statuses
    },
    isCreated() {
      return !this.mainData
    },
  },
  methods: {
    ...mapMutations({
      setCourses: 'dropdown/SET_COURSES',
      setProgramme: 'dropdown/SET_PROGRAMMES',
    }),
    ...mapActions({
      createData: `${Attr.store}/createData`,
      updateData: `${Attr.store}/updateData`,
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getCourseSemesters: 'dropdown/getCourseSemesters',
      getProgrammes: 'programme/getProgrammesByCourseId',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onTrainingSystemsChange(event) {
      this.targetData.courseId = null
      await this.getCourses({ organizationId: getUser().orgId, trainingSystemId: event })
    },
    async onCoursesChange(event) {
      this.targetData.courseSemesterId = null
      await Promise.all([
        this.getCourseSemesters({ organizationId: getUser().orgId, courseId: event }),
        this.getProgrammes({ courseId: event }),
      ])
    },

    async onShow() {
      this.isLoading = true
      if (this.mainData) {
        this.targetData = { ...this.mainData }
      }
      if (this.isCreated) {
        await this.getTrainingSystems({ organizationId: this.user.orgId })
        if (this.trainingSystems.length > 0) {
          this.targetData.trainingSystemId = this.trainingSystems[0].value || ''
          await this.getCourses({ trainingSystemId: this.trainingSystems[0].value || '' })
        }
        this.targetData.status = 0
      } else {
        await Promise.all([
          this.getTrainingSystems({ organizationId: this.user.orgId }),
          this.getCourses({ organizationId: getUser().orgId, trainingSystemId: this.targetData.trainingSystemId || '' }),
          this.getCourseSemesters({ organizationId: getUser().orgId, courseId: this.targetData.courseId }),
          this.getProgrammes({ courseId: this.targetData.courseId }),
        ])
      }
      this.isLoading = false
    },
    onHide() {
      this.$refs
        .diplomaSaveFormRef
        .reset()
      this.targetData = {
        id: null,
        trainingSystemId: null,
        courseId: null,
        courseSemesterId: null,
        programmeId: null,
        status: 0,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .diplomaSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createData(this.targetData)
            : await this.updateData(this.targetData)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('diplomaSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
