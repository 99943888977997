<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-form>
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label-for="trainingSystemId"
                  >
                    <v-select
                      v-model="filter.trainingSystemId"
                      :options="trainingSystems"
                      :reduce="option => option.value"
                      placeholder="Chương trình đào tạo/Bồi dưỡng"
                      @input="onTrainingSystemsChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label-for="courseId"
                  >
                    <v-select
                      v-model="filter.courseId"
                      :options="courses"
                      :reduce="option => option.value"
                      placeholder="Khóa học"
                      @input="onCoursesChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label-for="courseSemesterId"
                  >
                    <v-select
                      v-model="filter.courseSemesterId"
                      :options="courseSemesters"
                      :reduce="option => option.value"
                      placeholder="Học kỳ"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                  v-show="false"
                >
                  <b-form-group
                    label-for="departmentId"
                  >
                    <v-select
                      v-model="filter.departmentId"
                      :options="departments"
                      :reduce="option => option.value"
                      placeholder="Khoa/Bộ môn"
                      @input="onDepartmentChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                  v-show="false"
                >
                  <b-form-group
                    label-for="majorId"
                  >
                    <v-select
                      v-model="filter.majorId"
                      :options="majors"
                      :reduce="option => option.value"
                      placeholder="Chuyên ngành"
                      @input="onMajorsChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group
                    label-for="programmeId"
                  >
                    <v-select
                      id="programmeId"
                      v-model="filter.programmeId"
                      :options="programmes"
                      :reduce="option => option.value"
                      placeholder="Chương trình ĐT"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <div class="text-right">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mt-0"
                      @click="onSearchMainData"
                    >
                      <feather-icon icon="FilterIcon" /> Lọc
                    </b-button>
                    <b-button
                      v-if="creatable"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-modal.diplomaSaveModal
                      variant="success"
                      class="mt-0 ml-1"
                      @click="onCreateMainData"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="PlusIcon" /> Thêm mới
                      </span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                    đến {{(filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  style-class="vgt-table striped bordered"
                  row-style-class="vgt-row"
                  :columns="columns"
                  :rows="mainData"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  max-height="500px"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span v-else-if="props.column.field === 'currentQty'">
                      {{ props.row.approvedQty + '/' + props.row.currentQty }}
                    </span>
                    <span
                      v-else-if="props.column.field === 'action'"
                      class="thead-group-action"
                    >
                      <b-button-group>
                        <b-button
                          v-if="updatable"
                          v-b-modal.diplomaSaveModal
                          variant="primary"
                          class="btn-icon"
                          size="sm"
                          title="Chỉnh sửa"
                          @click="onEditMainData(props.row)"
                        >
                          <feather-icon icon="Edit2Icon" />
                        </b-button>
                        <b-dropdown
                          size="sm"
                          right
                          variant="success"
                        >
                          <b-dropdown-item
                            v-if="diplomaStudentReadable"
                            variant="success"
                            class="btn-icon"
                            size="sm"
                            @click="onShowDiplomaStudents(props.row)"
                          >
                            <feather-icon icon="ListIcon" />
                            Danh sách SV đăng ký
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-show="props.row.status >= 1 && classDiplomaPlanCreatable"
                            :disabled="(props.row.approvedQty === 0 || props.row.status !== 1)"
                            variant="success"
                            class="btn-icon"
                            size="sm"
                            @click="onPlanDiplomaClass(props.row)"
                          >
                            <feather-icon icon="ListIcon" />
                            Tạo lớp cố định
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="deletable"
                            :disabled="(props.row.status >= 1)"
                            variant="danger"
                            class="btn-icon"
                            size="sm"
                            title="Xóa"
                            @click="onDeleteMainData(props.row)"
                          >
                            <feather-icon icon="TrashIcon" />
                            Xóa
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-button-group>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                          đến {{(filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <DiplomaSave
      ref="diplomaSaveFormRef"
      :main-data="currentMainData"
      @succeed="onSucceed"
    />
    <b-modal
      id="modal-view-student"
      ref="modal-view-student"
      ok-title="Chấp nhận"
      cancel-title="Quay lại"
      centered
      size="xl"
      title="Danh sách học viên đăng ký song ngành"
      hide-header
      @ok="onSucceed"
      no-close-on-backdrop
    >
      <DiplomaStudent :data-send="currentMainData" />
    </b-modal>
    <b-modal
      id="modal-plan-class"
      ref="modal-plan-class"
      ok-title="Tạo lớp"
      cancel-title="Quay lại"
      centered
      size="xl"
      title="Dự kiến mở lớp cố định cho học viên đăng ký song ngành"
      hide-header
      :ok-disabled="!currentMainData || extMainData.planClassNum > currentMainData.approvedQty"
      @ok="onCreatePlanClass"
      no-close-on-backdrop
    >
      <DiplomaClassSave
        :data-send="currentMainData"
        :ext-data="extMainData"
      />
      <DiplomaStudentClassSave
        v-show="currentMainData && extMainData.planClassNum <= currentMainData.approvedQty"
        :data-send="currentMainData"
        :ext-data="extMainData"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  BForm,
  BFormGroup,
  BDropdown,
  BButtonGroup, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import DiplomaSave from '@/views/diploma/DiplomaSave.vue'
import DiplomaStudent from '@/views/diploma-student/DiplomaStudent.vue'
import DiplomaClassSave from '@/views/diploma/DiplomaClassSave.vue'
import DiplomaStudentClassSave from '@/views/diploma/DiplomaStudentClassSave.vue'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export const Attr = Object.freeze({
  store: 'diploma',
})
export default {
  name: 'Diploma',
  directives: {
    Ripple,
  },
  components: {
    DiplomaSave,
    DiplomaStudent,
    DiplomaClassSave,
    DiplomaStudentClassSave,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BCard,
    BRow,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    VueGoodTable,
    BFormGroup,
    BForm,
    vSelect,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: getUser().orgId,
        trainingSystemId: '',
        courseId: '',
        courseSemesterId: '',
        departmentId: '',
        majorId: '',
        programmeId: '',
        sort: '',
        status: '',
      },
      currentMainData: undefined,
      extMainData: {
        planClassNum: 1,
        planClassData: [],
        planStudentClassData: [],
      },
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 100],
      currentName: '',
      currentCode: '',
    }
  },
  computed: {
    ...mapGetters({
      mainData: `${Attr.store}/dataLists`,
      totalRows: `${Attr.store}/totalRows`,
      statuses: `${Attr.store}/statuses`,
      resourceName: `${Attr.store}/resourceName`,
      courses: 'dropdown/courses',
      trainingSystems: 'dropdown/trainingSystems',
      courseSemesters: 'dropdown/courseSemesters',
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
      programmes: 'dropdown/programmes',
    }),
    statusFilterDropdownItems() {
      return this.statuses.map(item => ({ value: item.value, text: item.label }))
    },
    columns() {
      return [
        {
          label: '#',
          field: 'rowNum',
          width: '30px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: `Tên ${this.resourceName.shortName}`,
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Chương trình ĐT',
          field: 'programmeName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Học kỳ ĐK',
          field: 'semesterName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Khóa',
          field: 'courseName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Hệ ĐT',
          field: 'trainingSystemName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Số lượng ĐK',
          field: 'currentQty',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.statusFilterDropdownItems,
            placeholder: 'Tất cả',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          width: '80px',
          sortable: false,
          tdClass: 'text-center',
        },
      ]
    },
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.DIPLOMA)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.DIPLOMA)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.DIPLOMA)
    },
    diplomaStudentReadable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.DIPLOMA)
        || hasPermissionForResource(PermissionCode.FETCH, ResourceCode.DIPLOMA)
    },
    classDiplomaPlanCreatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.CLASS_DIPLOMA_PLAN)
    },
  },
  async created() {
    this.updateParams({ organizationId: this.user.orgId })
    this.isLoading = true
    try {
      await Promise.all([
        this.getTrainingSystems(this.filter),
        // this.getDepartments(this.filter),
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapMutations({
      setCourses: 'dropdown/SET_COURSES',
      setMajors: 'dropdown/SET_MAJORS',
      setProgramme: 'dropdown/SET_PROGRAMMES',
    }),
    ...mapActions({
      getMainData: 'diploma/getData',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getCourseSemesters: 'dropdown/getCourseSemesters',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
      getProgrammes: 'dropdown/getProgrammes',
      createPlanData: 'classes/savePlanClassAndStudentsByDiploma',
    }),
    async onTrainingSystemsChange(event) {
      this.filter.courseId = null
      await this.getCourses({ organizationId: getUser().orgId, trainingSystemId: event })
    },
    async onCoursesChange(event) {
      this.filter.courseSemesterId = null
      await this.getCourseSemesters({ organizationId: getUser().orgId, courseId: event })
    },
    async onDepartmentChange(event) {
      this.setMajors([])
      this.filter.majorId = null
      this.setProgramme([])
      this.filter.programmeId = null
      if (event) {
        await this.onMajorsChange(this.filter)
      }
    },
    async onMajorsChange() {
      this.filter.programmeId = null
      const request = { ...this.filter }
      request.status = 1
      await this.getProgrammes(request)
    },
    onCreateMainData() {
      this.currentMainData = undefined
      this.extMainData = {}
    },
    onEditMainData(mainData) {
      this.isCopy = false
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    onDeleteMainData(mainData) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa ${this.resourceName.fullName}? <span class="text-danger">${mainData.name}</span>`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteMainData(mainData.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                await this.getMainData(this.filter)
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getMainDataFromStore()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    async onSucceed() {
      await this.getMainDataFromStore()
    },
    async onSearchMainData() {
      await this.getMainDataFromStore()
    },
    onShowDiplomaStudents(data) {
      this.currentMainData = data
      this.$bvModal.show('modal-view-student')
    },
    onPlanDiplomaClass(data) {
      this.currentMainData = data
      this.extMainData = {
        planClassNum: 1,
        planClassData: [],
        planStudentClassData: [],
      }
      this.$bvModal.show('modal-plan-class')
    },
    async onCreatePlanClass() {
      this.$swal({
        title: 'Bạn chắc chắn muốn tạo lớp cố định chuyên ngành 2 theo danh sách dự kiến này?',
        text: 'Bạn có thể cập nhật thông tin và học viên trong lớp sau khi tạo!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            let response = null
            if (this.currentMainData.id) {
              const requestBody = {
                planClasses: this.extMainData.planClassData,
                planStudents: this.extMainData.planStudentClassData,
                diplomaId: this.currentMainData.id,
              }
              response = await this.createPlanData(requestBody)
            }
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                await this.getMainDataFromStore()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
  },
}
</script>
