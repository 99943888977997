<template>
  <div>
    <b-container fluid>
      <b-card
        no-body
        class="mb-md-0"
      >
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="mx-2 my-1">
            <b-row>
              <b-col cols="12">
                <h3>{{ modalTitle }}</h3>
              </b-col>
            </b-row>
            <b-form>
              <b-row>
                <b-col cols="3">
                  Tổng số học viên được duyệt: <strong>{{ dataSend.approvedQty }}</strong>
                </b-col>
                <b-col
                  cols="3"
                  class="text-right"
                >
                  Chia theo số lượng lớp:
                </b-col>
                <b-col cols="3">
                  <b-form-group
                    label-for="classNum"
                  >
                    <b-form-input
                      id="classNum"
                      v-model="extData.planClassNum"
                      name="classNum"
                      type="number"
                      placeholder="Để trống nếu muốn tạo tự động"
                    />
                  </b-form-group>
                </b-col>
                <!--                <b-col cols="3">-->
                <!--                  <b-button-->
                <!--                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
                <!--                    variant="success"-->
                <!--                    class="mt-0 ml-1"-->
                <!--                    @click="onViewPlanData"-->
                <!--                  >-->
                <!--                    <span class="text-nowrap text-right"> Xem trước-->
                <!--                    </span>-->
                <!--                  </b-button>-->
                <!--                </b-col>-->
              </b-row>
            </b-form>
            <b-row class="mt-0">
              <b-col cols="12">
                <vue-good-table
                  class="my-table"
                  row-style-class="vgt-row"
                  style-class="vgt-table striped bordered"
                  :columns="columns"
                  :rows="mainData"
                  :pagination-options="paginationOptions"
                  :line-numbers="true"
                  max-height="500px"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span
                      v-else-if="props.column.field === 'action'"
                      class="thead-group-action"
                    >
                      <b-button-group>
                        <b-button
                          v-b-modal.diplomaClassSaveModal
                          variant="primary"
                          class="btn-icon"
                          size="sm"
                          title="Chỉnh sửa"
                          @click="onEditMainData(props.row)"
                        >
                          <feather-icon icon="Edit2Icon" />
                        </b-button>
                        <b-button
                          variant="danger"
                          class="btn-icon"
                          size="sm"
                          title="Xóa"
                          @click="onDeleteMainData(props.row)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </b-button-group>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ props.total }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'

export const Attr = Object.freeze({
  store: 'diploma',
})
export default {
  name: 'DiplomaClassSave',
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormGroup,
    BForm,
    BFormInput,
  },
  props: {
    dataSend: {
      type: Object,
      default: () => {},
    },
    extData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 100,
        diplomaId: this.dataSend.id,
        programmeId: this.dataSend.programmeId,
        planClassNum: this.extData.planClassNum,
        planStudentNum: this.dataSend.approvedQty,
      },
      currentMainData: undefined,
      modalTitle: `Dự thảo lớp cố định chương trình 2 - ${this.dataSend.programmeName} - [${this.dataSend.courseName} - ${this.dataSend.semesterName}]`,
      extMainData: {
        parentId: this.dataSend.id,
        courseId: this.dataSend.courseId,
        courseSemesterId: this.dataSend.courseSemesterId,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Tên lớp',
          field: 'name',
          sortable: true,
        },
        {
          label: 'Mã lớp',
          field: 'code',
        },
        {
          label: 'Khóa',
          field: 'courseName',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      mainData: `${Attr.store}/planLists`,
      statuses: `${Attr.store}/statuses`,
      resourceName: `${Attr.store}/resourceName`,
    }),
    statusFilterDropdownItems() {
      return this.statuses.map(item => ({ value: item.value, text: item.label }))
    },
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.filter.itemsPerPage,
      }
    },
  },
  watch: {
    // listening change data init
    'extData.planClassNum': async function (newExtData) {
      if (newExtData) {
        this.updateParams({ planClassNum: newExtData })
        await this.getMainDataFromStore()
      }
    },
  },
  async created() {
    this.isLoading = true
    try {
      await this.getMainDataFromStore()
      // await this.getMainData(this.filter)
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMainData: `${Attr.store}/getPlanData`,
      deleteMainData: `${Attr.store}/deleteData`,
    }),
    onCreateMainData() {
      this.currentMainData = undefined
      this.extMainData = {
        parentId: this.dataSend.id || this.$route.params.id,
        courseId: this.dataSend.courseId,
      }
    },
    onEditMainData(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    onDeleteMainData(mainData) {
      this.$swal({
        title: 'Bạn chắc chắn muốn xóa?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteMainData(mainData.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getMainData(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
        this.extData.planClassData = [...this.mainData]
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    async onViewPlanData() {
      await this.getMainDataFromStore()
    },
    async onSucceed() {
      await this.getMainDataFromStore()
    },
  },
}
</script>
